<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import {Modal} from "@/components/UIComponents";
import {Tooltip} from "element-ui";

const user_data = JSON.parse(localStorage.getItem('user-info'));

export default {
  components: {
    Modal,
    [Tooltip.name]: Tooltip
  },
  data() {
    return {
      initialOrderModal: false,
      initialOrderModalInprogress: true,
      initialModalData: {
        id: '',
        balance: 0,
        equity: 0,
        max_balance_order_number: '',
        max_credit_order_number: '',
        max_balance_order_number_calc: '',
        max_credit_order_number_calc: ''
      },
      modalconfirm: false,
      confirmTaskModalInprogress: false,
    }
  },
  props: {
    row_data: {
      type: Object,
      description: 'Selected row',
    }
  },
  methods: {
    clean() {
      this.initialModalData = {
        id: '',
        balance: 0,
        equity: 0,
        initialOrder: '',
        max_balance_order_number_calc: '',
        max_credit_order_number_calc: ''
      }
      this.initialOrderModal = false;
    },
    modalCondition(resp) {
      if (isNaN(parseInt(resp.data.begin_equity)) || isNaN(parseInt(resp.data.begin_balance))) {
        this.$toast.warning(this.$t('data_not_extracted_yet'));
        return true
      }
      if (parseInt(resp.data.begin_equity) == 0 && parseInt(resp.data.begin_balance) == 0 && resp.data.bal_equity_dt !== null) {
        this.$toast.warning("balance and equity = 0, bal_equity_dt NOT NULL ");
        return true
      }
      return false
    },
    initialOrder() {
      this.initialOrderModalInprogress = true
      this.initialOrderModal = true;
      let success = resp => {
        if (resp.success) {
          if (this.modalCondition(resp)) {
            return notReadyYet()
          } else {
            this.initialModalData.id = resp.data.id
            this.initialModalData.balance = resp.data.begin_balance
            this.initialModalData.equity = resp.data.begin_equity
            this.initialModalData.max_balance_order_number = resp.data.max_balance_order_number
            this.initialModalData.max_credit_order_number = resp.data.max_credit_order_number
            this.initialModalData.max_credit_order_number_calc = resp.data.max_credit_order_number_calc
            this.initialModalData.max_balance_order_number_calc = resp.data.max_balance_order_number_calc
            this.initialOrderModalInprogress = false
          }
        } else {
          fail()
        }

      }
      let notReadyYet = resp => {
        this.initialOrderModal = false;
        this.clean()
      }
      let fail = resp => {
        this.initialOrderModal = false;
        this.clean()
        this.$toast.warning(this.$t('somethingwentwrong'));
      }
      this.$getInitialBalanceEquity(this.row_data.acc_id).then(success, fail)
    },
    updateInitialOrder() {
      if (
          isNaN(this.initialModalData.max_balance_order_number) ||
          this.initialModalData.max_balance_order_number === '' ||
          isNaN(this.initialModalData.max_credit_order_number) ||
          this.initialModalData.max_credit_order_number === ''
          ) {
        return this.$toast.warning(this.$t('emptyfields'))
      }
      this.initialOrderModalInprogress = true
      let success = resp => {
        if (!resp.success) {
          fail()
        }
        this.$toast.success(this.$t(resp.msg));
        this.clean()
        this.confirmTaskModal()
      }
      let fail = resp => {
        this.clean()
        this.$toast.warning(this.$t('somethingwentwrong'));
      }
      let param = {
        follower_id: this.initialModalData.id,
        max_balance_order_number_calc: parseInt(this.initialModalData.max_balance_order_number),
        max_credit_order_number_calc: parseInt(this.initialModalData.max_credit_order_number),
      }
      this.$updateInitialOrder(param).then(success, fail)
    },
    done() {
      if(this.row_data.acc_type == 'master'){
        this.confirmTaskModal()
      } else {
        this.initialOrder()
      }
    },
    confirmTaskModal(){
      this.modalconfirm = true;
    },
    confirmedTaskDone() {
      this.confirmTaskModalInprogress = true

      let success = resp => {
        this.confirmTaskModalInprogress = false
        this.modalconfirm = false
        this.$toast.success(this.$t(resp.msg))
        return this.$emit('on-close', this)
      }
      let failop = resp => {
        this.confirmTaskModalInprogress = false
        this.$toast.error(this.$t(error))
      }
      this.$newPlatformsDone(this.row_data.id, user_data.email).then(success, failop);
    },
  }
}
</script>

<template>
  <div>
    <el-tooltip :content="`${$t('task_completed')}`" placement="top" v-if="row_data.date_ready && row_data.bal_equ_ready">
      <p-button type="primary" link v-on:click="done()" >
        <i class="fa fa-check-square-o " style="font-size: x-large;"></i>
      </p-button>

    </el-tooltip>
    <el-tooltip :content="`${$t('data_not_extracted_yet')}`" placement="top" v-else>
      <p-button type="default" link>
        <i class="fa fa-hourglass-half" style="font-size: x-large;"></i>
      </p-button>
    </el-tooltip>

    <modal :show.sync="initialOrderModal" headerClasses="">
      <h4 slot="header" class="title title-up"> {{ $t('set_initial_order') }} </h4>

      <div v-if="initialOrderModalInprogress" class="text-center">
        <h6> {{ $t('loadingtxt') }} </h6>
        <i class="fa fa-cog fa-spin fa-4x fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="text-left">
        <div class="row mt-4">
          <div class="col-sm-6">
            <h6>{{ $t('initial_balance') }}</h6>{{ initialModalData.balance }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('initial_equity') }}</h6>{{ initialModalData.equity }}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label> {{ $t('max_balance_order_calc_label') }}</label>
            <fg-input replace="[^0-9]" type="number" v-model="initialModalData.max_balance_order_number"></fg-input>
          </div>
          <div class="col-6">
            <label> {{ $t('max_credit_order_calc_label') }}</label>
            <fg-input replace="[^0-9]" type="number" v-model="initialModalData.max_credit_order_number"></fg-input>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button type="success" @click.prevent="updateInitialOrder" link>{{ $t('set_initial_order') }}</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="default" v-on:click="clean()" link>{{ $t('back') }}</p-button>
        </div>
      </template>
    </modal>

    <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up">{{ $t('newplatforms') }} {{ $t('completedtxt') }}?</h4>

      <div v-if="confirmTaskModalInprogress" class="text-center">
        <h6> {{ $t('loadingtxt') }} </h6>
        <i class="fa fa-cog fa-spin fa-4x fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">
        <div class="col-12">
          {{ $t('confirmtaskdone') }}
        </div>
      </div>

      <template slot="footer">
        <div class="left-side">
          <p-button @click.prevent="confirmedTaskDone" link>{{ $t('yestxt') }}</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" v-on:click="modalconfirm = false" link>{{ $t('notxt') }}</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<style scoped>

</style>
