<template>
  <module
      ref="module"
      id="newplatforms"
      titleIcon="nc-icon nc-laptop"
      :title="$t('newplatforms')"
      :use-default-list="false"
    >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

        <!-- Modal for Confirm Removal -->
        <modal :show.sync="modalassets" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('newplatforms') }} {{ $t('assetmatching') }}</h4>

          <div class="col-12 text-center" v-if="alert_me_modal">
            <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
              {{ alert_msg }}
            </div>
          </div>

          <div class="row" v-if="mtradedassets.length > 0">
            <div class="col-12">
              {{ $t('mastertxt') }} {{ helper_tradedassets }}
            </div>
          </div>
          <div class="row" v-if="mtradedassets.length > 0">
            <div class="col-12">
              <h6>{{ mtradedassets }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <fg-input v-model="assets" addon-left-icon="fa fa-keyboard-o" type="text" :placeholder="helper_tradedassets"></fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <fg-input v-model="assetsuffix" addon-left-icon="fa fa-keyboard-o" type="text" :placeholder="helper_assetsuffix"></fg-input>
            </div>
          </div>

          <div class="row">&nbsp;</div>

          <div class="row">
            <div class="col-12 text-center">
              <p-checkbox v-model="completed">{{ $t('completedtxt') }}?</p-checkbox>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="confirmedDone" link>{{ $t('finish') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalassets = false" link>{{ $t('notxt') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <div class="col-12 text-center" v-if="alert_me">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">

            <div class="col-sm-2">
              <label> {{ sortbyTxt }}</label>
              <el-select v-model="sortby.column" :placeholder="sortbyTxt"
                v-on:change="sortByCol">
                <el-option class="select-default"
                  v-for="item in sortby.columns"
                  :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ perpageTxt }}</label>
              <el-select
                v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ sortbyOrder }}</label>
              <el-select
                v-model="sortby.order" :placeholder="sortbyOrder"
                v-on:change="sortByCol">
                <el-option class="select-default"
                  v-for="item in sortby.orders"
                  :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-6">
              <div class="pull-right">
                <label> {{ searchTxt }}</label>
                <fg-input class="input-sm"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="queriedData"
                        border
                        style="width: 100%">
                <el-table-column :label="actionlbl" class="success" min-width="250" width="250">
                  <div slot-scope="scope" class="text-center">
                    <div class="btn-group">

                     <initial-order :row_data="scope.row" @on-close="completedTask()" ></initial-order>

                      <!--
                      <p-button type="success" link v-on:click="modify(`${scope.row.id}`)" v-if="scope.row.acc_type !== 'master' && !scope.row.confirmed">
                        <i class="fa fa-pencil" style="font-size: x-large;"></i>
                      </p-button>
                      -->
                      <el-tooltip :content="`${$t('show_pass')}`" placement="top">
                        <p-button type="danger" link v-on:click="showpass(`${scope.row.id}`, `${scope.row.password}`)" v-if="!scope.row.showingpass">
                          <i class="fa fa-key" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <p-button type="success" link v-if="scope.row.showingpass">
                        <i class="fa fa-key" style="font-size: x-large;"></i>
                      </p-button>

                      <p-button type="primary" round icon link v-if="scope.row.acc_type == 'master'">M</p-button>
                      <p-button type="info" round icon link v-if="scope.row.acc_type !== 'master'">F</p-button>

                    </div>
                  </div>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('account')}`">
                  <template slot-scope="scope">
                    <span v-on:click="copyit(scope.row.account)" class="clickable">
                      {{ scope.row.account }} ({{ scope.row.platform.toUpperCase() }})
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('userid')}`">
                  <template slot-scope="scope">
                    {{ scope.row.userid }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('fullname')}`">
                  <template slot-scope="scope">
                    {{ scope.row.first_name }} {{ scope.row.last_name }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('strategytxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.strat_name }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('servertxt')}`">
                  <template slot-scope="scope">
                    <span v-on:click="copyit(scope.row.server)" class="clickable">
                      {{ scope.row.server }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('mattxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.location }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('copyoldtrades')}`">
                  <template slot-scope="scope">
                    {{ scope.row.copyoldtrades }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('date')}`">
                  <template slot-scope="scope">
                    {{ $moment(scope.row.entry_dt).format("YYYY-MM-DD HH:mm:ss") }}
                  </template>
                </el-table-column>

              </el-table>

              <input id="clipit" type="text" v-model="clipboard" style="display: none">
            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>

</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Button, Modal} from 'src/components/UIComponents'
  import {Tooltip} from 'element-ui'
  import InitialOrder from "@/components/Dashboard/Views/Dashboard/ChangeLogs/initial-order.vue";

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(require('vue-moment'))

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  let bills = [];
  let timeoutcount = 1500;

  export default {
    components: {
      InitialOrder,
      PPagination, [Button.name]: Button, Modal,
      [Tooltip.name]: Tooltip
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {

        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        searchTxt: this.$t('searchtxt'),
        colSort: 'entry_dt',
        inprogress: true,
        actionlbl: this.$t('action'),
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        assets: null,
        completed: false,
        alert_me_modal: false,
        helper_tradedassets: this.$t('assetmatching'),
        modalassets: false,
        mtradedassets: [],
        assetsuffix: null,
        helper_assetsuffix: this.$t('helper_assetsuffix'),
        inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('newplatforms') + ". " + this.$t('pleasewait'),
        sortby: {
          column: 'location',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'userid',
              label: this.$t('userid')
            },
            {
              prop: 'first_name',
              label: this.$t('firstname')
            },
            {
              prop: 'last_name',
              label: this.$t('lastname')
            },
            {
              prop: 'strat_name',
              label: this.$t('strategytxt')
            },
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'server',
              label: this.$t('servertxt')
            },
            {
              prop: 'location',
              label: this.$t('mattxt')
            },
            {
              prop: 'entry_dt',
              label: this.$t('date')
            }
          ]
        },
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['userid', 'first_name', 'last_name', 'account', 'server', 'location'],
        tableColumns: [
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'first_name',
            label: this.$t('firstname')
          },
          {
            prop: 'last_name',
            label: this.$t('lastname')
          },
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          },
          {
            prop: 'server',
            label: this.$t('servertxt')
          },
          {
            prop: 'location',
            label: this.$t('mattxt')
          },
          {
            prop: 'entry_dt',
            label: this.$t('date')
          }
        ],
        tableData: [], modalconfirm: false, passid: null, did: null,
        clipboard: '',
        initialOrderModal: false,
        initialOrderModalInprogress: true,
        initialModalData:{
          balance: 0,
          equity: 0,
          initialOrder: ''
        }
      }
    },
    methods: {
      copyit(value) {
        let cb = document.getElementById("clipit");
        cb.style.display = 'block';
        cb.value = value;
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
        this.$toast.success(`${value} Copied!`)
      },
      closeModalAlert() {
        this.modalconfirm = false;
        this.did = null;
      },
      completedTask() {
        this.$refs.module.refreshList();
        this.inprogress = true;
        this.$getNewPlatforms().then(this.loadNewPlatforms, this.failop);
      },
      confirmedDone() {
        if (this.completed) {
          const m = this.tableData.findIndex(t => parseInt(t.id) === parseInt(this.did));
          if (m >= 0) {
            this.tableData[m].confirmed = true;
            this.modalassets = false;
            this.alert_me_modal = false;
            this.alert_msg = null;
            this.alert_class = 'text-danger';

            this.assets = null;
            this.completed = false;
          }
        }
        else {
          this.alert_me_modal = true;
          this.alert_msg = this.$t('pleaseconfirmdone');
          this.alert_class = 'text-danger';
        }
      },
      done(id) {
        this.did = id;
        this.modalconfirm = true;
      },
      resetAlert() {
        this.alert_me = false;
        this.alert_msg = null;
        this.alert_class = 'text-danger';
      },
      modify(id) {
        this.did = id;
        this.completed = false;
        this.modalassets = true;
      },
      loadNewPlatforms(resp) {
        this.tableData = [];
        for(var i=0;i < resp.data.length;i++) {
          let s = resp.data[i];

          s['confirmed'] = s['acc_type'] == 'master';
          s['showingpass'] = false;
          s['public'] = this.$t('notxt');
          if (s.discoverable) {
            s['price'] = (parseFloat(s['price']) * 100).toString() + "%";
            s['public'] = this.$t('yestxt');
          }
          if (s.copy_all_trades) {
            s['copyoldtrades'] = 'Yes'
          } else {
            s['copyoldtrades'] = 'No'
          }
          this.tableData.push(s);
        }

        this.sortByCol();
        //this.tableData = resp.strategies.slice(0);

        this.inprogress = false;
      },
      showpass(id, pwd) {

        const el = document.createElement('textarea');
        el.value = pwd;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (this.passid !== null && this.passid !== id) {
          const prid = this.tableData.findIndex(t => t.id === this.passid);
          this.tableData[prid].showingpass = false;
        }

        const rid = this.tableData.findIndex(t => t['id'] == id);

        if (rid >= 0) {
          this.passid = rid;
          this.tableData[rid].showingpass = true;
          const removeShowingPass = id => {
            this.tableData[rid].showingpass = false;
            this.passid = null;
          }
          setTimeout(removeShowingPass, timeoutcount);
        }
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortby.order == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol() {

        this.colSort = this.sortby.column;
        this.tableData.sort(this.sortIt);
        /*
        if (this.sortby.column == 'userid' || this.sortby.column == 'first_name' || this.sortby.column == 'last_name') {

        }
        else {
          this.tableData.sort(this.sortItNum);
        }
        */
      },
      failop (error) {
        if (error.data && error.data.msg) {
          error = error.data.msg;
        }
        this.inprogress = false
        this.$toast.error(this.$t(error))
      }
    },
    mounted () {
      this.$getNewPlatforms().then(this.loadNewPlatforms, this.failop);
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
  }
</script>
<style>
</style>
